/* header {
    background: url("../../asserts/images/about_banner.svg");
} */

.about-pastor-title {
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: normal;
    font-size: 34px;
    line-height: 45px;
    color: #7541B7;
}

.about-pastor-subtitle {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 500;
    font-size: 26px;
    line-height: 30px;
    color: #DA281D;
}

.about-pastor-details {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 23px;
    color: #4D4D4D;
    text-align: justify;
} 

.about-pastor-img {
    max-width: 381px;
    /* box-shadow: #FFBD10 -13px -12px 0px; */
    border-radius: 0px 83px 83px 83px;
}

.pastor-testimony-title {
    font-family: 'Roboto Slab';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 32px;
    color: #DD3B66;
}

.pastor-testimony-details {
    font-family: 'Roboto';
    font-weight: normal;
    font-style: normal;
    font-size: 20px;
    line-height: 23px;
    text-align: justify;
    color: #4D4D4D;

}

.header-about {
    background: url('../../asserts/images/AboutUs/AboutUs_Banner.png');     
     width: 100%;
     height: 75vh;
     left: 0px;
    background-size: cover;
}

.about-tag {
    position: relative;
    height: 100%;
}

.about-tag-text {
    position: absolute;
    background: rgba(84, 26, 29, 0.83);
    font-size: 34px;
    height: 76px;
    color: #FFFFFF;
    width: 100%;
    left: 0px;
    bottom: 0px;
    font-family: 'Roboto';
}
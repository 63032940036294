
.our-belief-section {
    background: #fff7e2;
    margin:40px auto;
}

.belief-header {
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 47px;
}

.belief-sub-header {
    font-style: normal;
    font-weight:500;
    font-size: 26px;
    line-height: 30px;
}

.belief-detail {
    font-weight: normal;
    font-style: normal;
    font-size: 20px;
    line-height: 23px;
    text-align: justify;
    color: #4D4D4D;
}
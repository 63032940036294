/* Outreach Ministries Styles */

.outreach-title, .children-ministry-title, 
.youth-ministries-title, .family-ministries-title {
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: normal;
    font-size: 34px;
    line-height: 45px;
    color: #7541B7;
}

.outreach-subtitle, .children-ministry-subtitle, 
.youth-ministries-subtitle, .family-ministries-subtitle {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 500;
    font-size: 26px;
    line-height: 30px;
    color: #DA281D;
}

.outreach-details, .children-ministry-details, 
.youth-ministries-details, .family-ministries-details {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 23px;
    color: #4D4D4D;
    text-align: justify;
} 

img {
    max-width: 100%;
}

.nannilam-outreach-title {
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 20px;
    color: #535D74;
}

.nannilam-img img, .children-ministry-img img, .youth-ministry-img img, .family-ministry-img img {
    border:6px solid #ECECEC;
}


.header-ministry {
    background: url('../../asserts/images/OurMinistries/Ourministries_Banner.png');     
     width: 100%;
     height: 75vh;
     left: 0px;
    background-size: cover;
}

.ministry-tag {
    position: relative;
    height: 100%;
}

.ministry-tag-text {
    position: absolute;
    background: rgba(84, 26, 29, 0.83);
    font-size: 34px;
    height: 76px;
    color: #FFFFFF;
    width: 100%;
    left: 0px;
    bottom: 0px;
    font-family: 'Roboto';
}

.icon {
    display: inline-block;
    width: 50px;
    height: 50px;
    background-size: cover;
  }

.icon-outreach {
    background-image: url('../../asserts/images/OurMinistries/outreach_icon.svg');
  }
  .icon-outreach-active {
    background-image: url('../../asserts/images/OurMinistries/outreach_icon_active.svg');
  }

  .icon-children {
    background-image: url('../../asserts/images/OurMinistries/children_icon.svg');
  }
  .icon-children-active {
    background-image: url('../../asserts/images/OurMinistries/children_icon_active.svg');
  }

  .icon-youth {
    background-image: url('../../asserts/images/OurMinistries/youth_icon.svg');
  }
  .icon-youth-active {
    background-image: url('../../asserts/images/OurMinistries/youth_icon_active.svg');
  }  

  .icon-family {
    background-image: url('../../asserts/images/OurMinistries/family_icon.svg');
  }
  .icon-family-active {
    background-image: url('../../asserts/images/OurMinistries/family_icon_active.svg');
  }
/* Header Component Styles */



.header-main {
    display: flex;
    background-image: url("../../asserts/images/HomePage/Home_Banner.png");
    width: 100%;
    height: 100vh;
    justify-content: space-between;
    left: 0px;
    background-size: cover;
}



.m-custom {
    margin-bottom: 4rem;
}

.home-banner-div {
    padding-right: 7rem;
}

.home-banner-img {
    max-width:300px;
    border-radius:50%;
    border:2px solid #FFFFFF;
}

.home-banner-header {
    font-family: 'TAMUni-Tamil150';
    font-size: 7.125em;
    line-height: 75.3%;
    color: #FFC700;
    text-shadow:-2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000, 2px 2px 0 #000;
   
}

.home-banner-loc {
    font-family: 'TAMUni-Tamil046';
    font-size: 8.125em;
    line-height: 75.3%;
    color: #FFFFFF;
    text-shadow:-2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000, 2px 2px 0 #000;
   
}

.home-banner-tamil {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.home-banner-tamil-align {
    padding-left: 20px;
    padding-top: 10%;
}

.home-tag {
    display: flex;
    align-items: center;
}

.home-tag-text {
    position: absolute;
    background: #92317C;
    font-size: 34px;
    height: 17px;
    color: #FFFFFF;
    width: 100%;
    left: 0px;
    bottom: 0px;
    font-family: 'Roboto';
} 
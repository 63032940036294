.giving-title {
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: normal;
    font-size: 34px;
    line-height: 45px;
    color: #7541B7;
}

.giving-subtitle {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 500;
    font-size: 26px;
    line-height: 30px;
    color: #DA281D;
}

.bank-title {
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 37px;
    color: #55515A;
}

.bank-detail-card {
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.11);
    border-radius: 11px;
    
}

.bank-label {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 33px;
    color: #55515A;
    margin-top: 1.5rem;
}

.bank-details {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 33px;
    color: #E93253;
    margin-top: 1.5rem;
}

.mail-detail {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 33px;
    color: #000000;
}


.header-giving {
    background: url('../../asserts/images/Giving_Banner.png');     
     width: 100%;
     height: 75vh;
     left: 0px;
    background-size: cover;
}

.giving-tag {
    position: relative;
    height: 100%;
}

.giving-tag-text {
    position: absolute;
    background: rgba(84, 26, 29, 0.83);
    font-size: 34px;
    height: 76px;
    color: #FFFFFF;
    width: 100%;
    left: 0px;
    bottom: 0px;
    font-family: 'Roboto';
}
/* Footer CSS */

.about-church-header {
font-family: 'Roboto Slab';
font-weight:normal;
font-style: normal;
font-size: 30px;
line-height: 40px;
color: #FFFFFF;
}


.church-details {
font-family: 'Roboto';
font-style: normal;
font-weight: 300;
font-size: 18px;
line-height: 30px;
text-align: justify;
color: #FFFFFF;
}

.footer-note {
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    font-family: 'Roboto';
    line-height: 19px;
    color: #FFFFFF;
}

.footer-contain {
    background: #353535;
    position: absolute;
}

.margin-space {
    margin-top: 4rem;
}

.footer-img-contain {
    display: flex;
    justify-content: center;
    
}

.footer-map {
    width: 400px;
    border-radius: 20px;
    height: 245px;
   
}

.map-contain {
    display: flex;
    justify-content: center;
}
.youtube-video-player iframe{
    border-radius: 3px;
    border: 5px solid #dee2e6;
    
}

.slides .slick-prev,
  .slides .slick-next {
    position: absolute;
    top: 50%;
    z-index: 1;
  }
  

 .slides .slick-prev:before{
    width: 62px;
    height: 62px;
    content: '' !important;
    position: absolute;
    top: -11px;
    left: -38px;
    background-image: url('../asserts/images/arrow-prev.png');
    background-repeat: no-repeat;
    background-size: 42px 42px;
    opacity: 1;
 }

 .slides .slick-next:before{
  width: 62px;
  height: 62px;
  content: '' !important;
  position: absolute;
  top: -11px;
  right: -54px;
  background-image: url('../asserts/images/arrow-next.png');
  background-repeat: no-repeat;
  background-size: 42px 42px;
  opacity: 1;
}

.slides .slick-next:hover:before, .slides .slick-prev:hover:before {

  opacity: 0.9;
}

.slides {
    position: relative;
  }
  
 
 .slider-outer-div {
     padding: 10px;
     cursor: pointer;
     outline: none;
 }
.bg-nav-color {
    background: rgba(99, 18, 52, 0.71);
    height: 91px;
}

.logo-contain {   
    width: 291px;
    height: 1px;
    border-top: 107px solid #ffffff;
    border-right: 107px solid transparent;
    transform: matrix(1, 0, 0, 1, -16, 0);
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.47));
    /* background: #FFFFFF; */
    /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.47); */
}

.logo {
    position: absolute;
    margin: -95px 0px 0px 20px ;
}

.nav-menu {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    /* padding: .5rem 1.5rem !important; */
    margin: auto 0.8rem;
    letter-spacing: 1px;
}

.liveIcon {
    vertical-align: middle;
    width: 54px;
}   

.socialIcon {
    width: 35px !important;
    height: 35px;
    font-size: 12px;
    color: #FFFFFF;
    background-color: #3B5998;
    padding: 8px;
    border-radius: 50%;

}

.nav-icon {
    display: flex;
    align-items: center;
}
.nav-menu:hover {
    color: #FFFFFF;
    opacity: 0.7;
}


.active-menu {
    border-bottom: 4px solid #FFC93E;
}

.nav-icon-sm {
    display: none;
    align-items: center;
}

.navbar-toggler:focus, .navbar-toggler:hover {
    outline: none;
}

.navbar-toggler .menuIcon:focus {
    color: #ffffff80;
}

.menuHide {
    transition: all 0.4s ease-out;
}

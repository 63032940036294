@font-face {
  
  font-family:'TAMUni-Tamil150';  
  src: local('TAMUni-Tamil150'), url('./asserts/fonts/TAMUni-Tamil150.ttf') format('truetype');
 
}

@font-face {
  font-family: 'TAMUni-Tamil046';  
  src: local('TAMUni-Tamil046'), url('./asserts/fonts/TAMUni-Tamil046.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif, 'Roboto Slab', serif, 'TAMUni-Tamil046', 'TAMUni-Tamil150' ;
  
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/* Section Margin Y position */
.smy-1 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.smy-2 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.smy-3 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.smy-4 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.smy-5 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}


/*Section Margin X position */
.smx-1 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.smx-2 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

.smx-3 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.smx-4 {
  margin-left: 4rem !important;
  margin-right: 4rem !important;
}

.smx-5 {
  margin-left: 5rem !important;
  margin-right: 5rem !important;
}

/*Section padding Y position */

.spy-1 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.spy-2 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.spy-3 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.spy-4 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.spy-5 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

/*Section padding X position */
.spx-1 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.spx-2 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.spx-2-5 {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}

.spx-3 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.spx-4 {
  padding-left: 4rem !important;
  padding-right: 4rem !important;
}

.spx-5 {
  padding-left: 5rem !important;
  padding-right: 5rem !important;
}


/*Section Margin top position */

.smt-1 {
  margin-top: 1rem !important;
}

.smt-2 {
  margin-top: 2rem !important;
}

.smt-3 {
  margin-top: 3rem !important;
}

.smt-4 {
  margin-top: 4rem !important;
}

.smt-5 {
  margin-top: 5rem !important;
}

/*Section Margin bottom position */
.smb-1 {
 margin-bottom: 1rem !important;
}

.smb-2 {
  margin-bottom: 2rem !important;
}

.smb-3 {
  margin-bottom: 3rem !important;
}

.smb-4 {
  margin-bottom: 4rem !important;
}

.smb-5 {
  margin-bottom: 5rem !important;
}

/*Section Margin left position */
.sml-1 {
  margin-left: 1rem !important;
}

.sml-2 {
  margin-left: 2rem !important;
}

.sml-3 {
  margin-left: 3rem !important;
}

.sml-4 {
  margin-left: 4rem !important;
}

.sml-5 {
  margin-left: 5rem !important;
}


/*Section Margin right position */
.smr-1 {
  margin-right: 1rem !important;
}

.smr-2 {
  margin-right: 2rem !important;
}

.smr-3 {
  margin-right: 3rem !important;
}

.smr-4 {
  margin-right: 4rem !important;
}

.smr-5 {
  margin-right: 5rem !important;
}


/*Section padding top position */
.spt-1 {
  padding-top: 1rem !important;
}

.spt-2 {
  padding-top: 2rem !important;
}

.spt-3 {
  padding-top: 3rem !important;
}

.spt-4 {
  padding-top: 4rem !important;
}

.spt-5 {
  padding-top: 5rem !important;
}

/*Section padding bottom position */
.spb-1 {
  padding-bottom: 1rem !important;
}

.spb-2 {
  padding-bottom: 2rem !important;
}

.spb-3 {
  padding-bottom: 3rem !important;
}

.spb-4 {
  padding-bottom: 4rem !important;
}

.spb-5 {
  padding-bottom: 5rem !important;
}

/*Section padding left position */
.spl-1 {
  padding-left: 1rem !important;
}

.spl-2 {
  padding-left: 2rem !important;
}

.spl-3 {
  padding-left: 3rem !important;
}

.spl-4 {
  padding-left: 4rem !important;
}

.spl-5 {
  padding-left: 5rem !important;
}

/*Section padding right position */
.spr-1 {
  padding-right: 1rem !important;
}

.spr-2 {
  padding-right: 2rem !important;
}

.spr-3 {
  padding-right: 3rem !important;
}

.spr-4 {
  padding-right: 4rem !important;
}

.spr-5 {
  padding-right: 5rem !important;
}
.live-service-home {
    width: 50vmax;
    height: 30vw;
    border: 10px solid #000000;
    border-radius: 13px 13px 0px 0px;
}

.live-home {
    font-size: 34px;
    font-family: 'Roboto Slab';
    font-weight: bold;
    line-height: 45px;
    color: #B74179;
}

.prayer-time {
    font-size: 34px;
    font-family: 'Roboto Slab';
    font-weight: bold;
    line-height: 45px;
    color:#308AB0;
}

.prayer-card .card-sun {
    background-color: #FCFFE8;
    border: 1px solid #50FF76;
    border-radius: 10px;
}

.prayer-card .card-tue {
    background-color: #FFF7E8;
    border: 1px solid #FFB950;
    border-radius: 10px;
}

.prayer-card .card-fri {
    background-color: #E8FFF9;
    border: 1px solid #50ABFF;
    border-radius: 10px;
}

.prayer-card .card-title {
    color: #000000;
    font-weight: 500;
    font-size: 24px;
    font-style: normal;
    font-family: 'Roboto Mono';
    /* line-height: 28px; */
    position :relative;
    text-decoration: none; 
}

.prayer-card .card-body {
    margin: auto 1rem;
}

.prayer-card .card-t-1:after {
    position: absolute;
    content: '';
    height: 3px;
    bottom: -5px;
    /* margin: 0 auto; */
    left: 3px;
    right: 0;
    width: 44%;
    background: #80b159;
    transition: .5s;
}

.prayer-card .card-t-2:after {
    position: absolute;
    content: '';
    height: 3px;
    bottom: -5px;
    /* margin: 0 auto; */
    left: 5px;
    right: 0;
    width: 44%;
    background: #b15959;
    transition: .5s;
}

.prayer-card .card-t-3:after {
    position: absolute;
    content: '';
    height: 3px;
    bottom: -5px;
    /* margin: 0 auto; */
    left: 2px;
    right: 0;
    width: 41%;
    background: #59b1a1;
    transition: .5s;
}

/* .prayer-card .card-title:hover:after {
    width: 80%;
    background: orange;
} */

.first-service {
    font-family: 'Roboto';
    font-weight: normal;
    font-style: normal;
    font-size: 18px;
    line-height: 38px;
    letter-spacing: 0.07em;
    position: relative;
    text-decoration: none;

}

.first-service:after {    
    position: absolute;
    content: '';
    height: 1px;
    bottom: -5px;
    /* margin: 0 auto; */
    left: 2px;
    right: 0;
    width: 95%;
    background: #c9c9c9;
    transition: .5s;
    
}

.second-service {
    font-family: 'Roboto';
    font-weight: normal;
    font-style: normal;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.07em;
    position: relative;
    text-decoration: none;

}

.first-service span {
    margin-right: 30px;
}

.live-video-title {
    background: black;
    color: #FFA800;
    margin-top: -8px;
    border-radius: 0px 0px 10px 10px;
    font-size: 20px;
    text-align: left;
    padding: 10px;
}




.prayer-title {
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: normal;
    font-size: 34px;
    line-height: 45px;
    color: #7541B7;
}

.prayer-subtitle {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 500;
    font-size: 26px;
    line-height: 30px;
    color: #DA281D;
}

.prayer-details {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
    text-align: justify;
    color: #4D4D4D;
}

.prayer-form label {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #646464;

}

.prayer-form input, .prayer-form textarea {
    box-sizing: border-box;
    border: 1px solid #A2A2A2;
    border-radius: 0px !important;
}

.prayer-form {
    margin-top: 40px;
}

.btn-align {
    display: flex;
    justify-content: flex-end;
}

.btn-align button {
 background: #3F6CC4;
 border: 1px solid #FFFFFF;
 border-radius: 15px;
 color: #FFFFFF;
 padding: 13px 60px;
 font-family: 'Roboto';
 font-weight: normal;
}

.btn-align button:hover {
    background: #3F6CC4;
    }
   

.header-prayer {
    background: url('../../asserts/images/Prayer_Req_Banner.png');     
     width: 100%;
     height: 75vh;
     left: 0px;
    background-size: cover;
}

.prayer-tag {
    position: relative;
    height: 100%;
}

.prayer-tag-text {
    position: absolute;
    background: rgba(84, 26, 29, 0.83);
    font-size: 34px;
    height: 76px;
    color: #FFFFFF;
    width: 100%;
    left: 0px;
    bottom: 0px;
    font-family: 'Roboto';
}    
.audio-header {
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 37px;
    color: #108982;
}

.audio-songs-lists ul li {

    list-style: none;
    height: 43px;
   
}

.audio-songs-lists {
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.11);
    border-radius: 11px;
}

.play-pause-btn {
    
    cursor: pointer;
}

.play-list {
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
    padding-top: 5px;
}

.play-list:not(:last-child) {
    border-bottom: 1px solid #E7E7E7;
}

.play-pause-btn {
    margin-right: 10px ;
}
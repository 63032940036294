.each-video-title {
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    color: #365788;
    margin-left: 5px;

}

.thumbnail-card {
    background: #FFFFFF;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.11);
    border-radius: 11px;
}

.thumbnail-card-body {
    height: 91px;

}

.thumbnail-card-text {
    font-family: Roboto Slab;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;    
    color: #365788;
    padding: 10px;
    
}
  
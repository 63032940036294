@media (min-width: 576px) {  }

 /* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {

    .text-hide-lg {
        display: none;
    }

    .owl-prev, .owl-next {
        font-size: 65px!important;
    } 

    .outreach-slider {
        display: none;
    }

    .media-icons-sm, .ministry-section-show-sm {
        display: none !important;
    }

    .spx-sm {
        margin-left: 3rem !important;
        margin-right: 3rem !important;
    }

    
}

 /* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .prayer-card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 33.5vw;
    }
    
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {  }


 /* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
   
 }

 /* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) { 
    .footer-map {
        width: 100% !important;
    }

    .home-banner-tamil {
        flex-direction: column;
        padding: 4rem 0rem;
        width: 100%;
    }

    .navbar-collapse {
        background: rgba(99,36,56,.65);
    }

    .text-hide-sm {
        display: none;
    }

    .home-banner-div {
        padding-right: 0rem !important;
        justify-content: center;
        display: flex;
        width: 100%;
    }

    .live-service-home {
        width: 100%;
        height: 240px;
        border: 10px solid #000000;
        border-radius: 13px 13px 0px 0px;
    }

    .logo-contain {
        width: 160px;
        height: 1px;
        border-top: 59px solid #ffffff;
        border-right: 56px solid transparent;
        transform: matrix(1, 0, 0, 1, -16, -8);
    }

    .about-pastor-img {
        max-width: 100% !important;
        
    }

    .request-form-sec {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }

    .bg-nav-color {
        height: 56px;
    }
    .logo {
        position: absolute;
        margin: -53px 0px 0px 10px;
    }

    .navbar-toggler {
        padding: .25rem 0rem .25rem .75rem;
        width: 49px;
        transition: 2s ease-in-out;
    }

    .text-hide-lg {
       text-align: center;
    }

    .home-banner-header {
        font-size: 4em !important;
    }
    .home-banner-loc {
        font-size:5em !important;
    }

    .live-home {
        font-size: 22px;
    }

    .prayer-time {
        font-size: 22px;
    }

    .underline-decoration img {
        width: 30%;
    }

    .prayer-card .card-title {
       font-size: 18px;
    }

    .prayer-card .card-t-1:after, .prayer-card .card-t-2:after {
        width: 130px;
    }

    .prayer-card .card-t-3:after { 
        width: 123px;
    }
    
    .first-service, .second-service {
        font-size: 14px;
        line-height: 26px;
    }

    .belief-header {
        font-size: 24px;
        line-height: 28px;
    }

    .belief-sub-header {
        font-size: 18px;
        line-height: 19px;
    }

    .px-5 {
        padding-left: 1rem!important;
        padding-right: 1rem!important;
    }
    
    .spx-5 {
        padding-left: 2rem!important;
        padding-right: 2rem!important;
    }

    .belief-header {
        font-size: 24px!important;
        line-height: 28px!important;
    }

    .belief-sub-header {
        font-size: 16px !important;
        line-height: 19px !important;
    }

    .belief-detail {
        font-size: 16px!important;
        line-height: 20px!important;
    }
    .ministry-header {
        font-size: 22px!important;
        line-height: 30px!important;
    }

    .live-service-parent {
        margin-bottom: 30px;
    }

    .media-ministry .media-header {
        font-size: 40px!important;
        line-height: 47px!important;
    }

    .about-church-header {
        font-size: 24px!important;
        line-height: 40px!important;
    }

    .ministry-slider-card .card-title {
        font-size: 16px!important;
    }

    .owl-prev, .owl-next {
        width:30px!important;
        height: 30px!important;
        font-size: 40px!important;
    }  
    
    .owl-prev {
        left: -2.5rem!important;
    }
    .owl-next {
        right: -2.5rem!important;
    }

    .header-about {
        background: url('./asserts/images/AboutUs/family.png') no-repeat center!important;
        height: 300px!important;
        background-size:cover ;
    }

    .header-main {
        height: 92vh!important;
    }
    
    .about-tag-text, .prayer-tag-text, .giving-tag-text, .ministry-tag-text, .media-tag-text {
        font-size: 22px!important;
        height: 58px!important;       
        text-align: center!important;
    }

    .pastor-testimony-title {
        font-size: 18px!important;
        line-height: 28px!important;
    }

    .header-ministry, .header-giving, .header-prayer, .header-media {
        height: 300px !important;
    }

    .outreach-title, .children-ministry-title, .youth-ministries-title, .family-ministries-title, .prayer-title,
    .about-pastor-title, .giving-title, .bank-title, .media-title {
        font-size: 22px !important;
        line-height: 28px!important;
    }

    .title-underline-img {
        width: 120px!important;
    }

    .outreach-subtitle, .children-ministry-subtitle, .youth-ministries-subtitle, .family-ministries-subtitle,
    .prayer-subtitle, .about-pastor-subtitle, .giving-subtitle, .media-subtitle  {
        font-size: 16px !important;
        line-height: 20px !important;
    }

    .outreach-details, .children-ministry-details, .youth-ministries-details, .family-ministries-details, .prayer-details,
    .pastor-testimony-details, .about-pastor-details, .media-details {        
        font-size: 14px!important;
        line-height: 23px!important;
    }

    .nannilam-outreach-title {
        font-size: 16px!important;
    }

    .live-video-title {
        font-size: 10px;
    }

    .prayer-form {
        margin-top: 0px !important;
    }

    .mail-detail {
        font-size: 16px!important;
        line-height: 28px !important;
    }
    .bank-label {
        font-size: 16px!important;
        line-height: 0px!important;
    }

    .bank-details {
        font-size: 16px!important;
        line-height: 24px!important;
    }

    .bank-detail-card {
        display: inline-block;
    }

    .media-buttons {
        display: none!important;
    }

    .audio-header {
        font-size: 18px !important;
        line-height: 28px !important;
        text-align: center;
    }

    .ministries-small-hidden, .ministries-hide-small {
        display: none !important;
    }

    .outreach-ministry-slider .owl-prev, .outreach-ministry-slider .owl-next {
        top: 65%;
    }

    .each-video-title {
        font-size: 12px !important;
        line-height: 16px !important;
        text-align: center;
    }

    .youtube-video-player iframe {
        height: 180px;
    }

    .thumbnail-card-text {
        font-size: 14px !important;
        line-height: 20px !important;
    }
    .spx-sm {
        margin-left: 1.5rem !important;
        margin-right: 1.5rem !important;
    }

    .slides .slick-prev:before {
        width: 35px !important;
        height:35px !important;
        left: -18px !important;
        background-size: 35px 35px !important;
    }
    .slides .slick-next:before {
        width: 35px !important;
        height:35px !important;
        right: -18px !important;
        background-size: 35px 35px !important;
    }
    .video-songs {
        background: url('./asserts/images/HomePage/Video_Song_Mobile.png');
    }

    .video-sermon {
        background: url('./asserts/images/HomePage/Video_Sermon_mobile.png');
    }

    .audio-songs {
        background: url('./asserts/images/HomePage/Audio_Song_mobile.png');
    }

    .media-ministry {
        height: 248px !important;
    }

    .media-contain .btn-custom {
        padding: 13px 35px !important; 
    }
    
}

 /* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {

    .menuIcon {
        /* width: 35px !important;
    height: 35px; */
    font-size: 35px;
    color: #FFFFFF;
    
    }

    .nav-icon {
        display: none;
    }

    .nav-icon-sm {
        display: flex;
        align-items: center;
        margin-top: -19px;
        margin-left: -24px;
    }
 }

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) { }

@media (min-width: 576px) and (max-width: 768px) { 
    .logo-contain {
        margin-top: -9px;
    }
}


.media-title {
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: normal;
    font-size: 34px;
    line-height: 45px;
    color: #7541B7;
}

.media-subtitle {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 500;
    font-size: 26px;
    line-height: 30px;
    color: #DA281D;
}

.media-details {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 23px;
    color: #4D4D4D;
    text-align: justify;
} 

.text-cus-color {
    color:#000000;
}

.media-buttons {
    background: #EDEDED;
    border-radius: 42.5px;
    display: inline-flex;
    justify-content: space-around;
    height: 65px;
}

.media-buttons .btn {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    padding: 0px 75px;
    color: #4b4b4b;
    border-radius: 42.5px;
}

.media-buttons .btn:active {
    color: #FFFFFF;
    background: #EC5858;
    border-radius: 42.5px;
    box-shadow: none;
}

.media-buttons .btn:focus {
    box-shadow: none;
}

.media-button {
    color: #FFFFFF !important;
    background: #EC5858;
    border-radius: 42.5px;
    box-shadow: none;
}

.header-media {
    background: url('../../asserts/images/Media/Media_Banner.png');     
     width: 100%;
     height: 75vh;
     left: 0px;
    background-size: cover;
}

.media-tag {
    position: relative;
    height: 100%;
}

.media-tag-text {
    position: absolute;
    background: rgba(84, 26, 29, 0.83);
    font-size: 34px;
    height: 76px;
    color: #FFFFFF;
    width: 100%;
    left: 0px;
    bottom: 0px;
    font-family: 'Roboto';
}

/* .media-buttons-as-icons {
    color: #000000 !important;
    background: transparent;
    border-radius: 50%;
    box-shadow: none;
    width: 47px;
    height: 47px;
    font-size: 20px;
    border: 1.5px solid #000000;
}

.media-buttons-active-icons {
    color: #FFFFFF !important;
    background: #C65F8A;
    border-radius: 50%;
    box-shadow: none!important;
    width: 47px;
    height: 47px;
    font-size: 20px;
    border: none !important;
} */

.media-icons-sm {
    display: flex;
    justify-content: space-around;
}

.icon {
    display: inline-block;
    width: 50px;
    height: 50px;
    background-size: cover;
  }

.icon-audio {
    background-image: url('../../asserts/images/Media/headphone_icon.svg');
  }
  .icon-audio-active {
    background-image: url('../../asserts/images/Media/headphone_icon_active.svg');
  }

  .icon-video {
    background-image: url('../../asserts/images/Media/video_songs_icon.svg');
  }
  .icon-video-active {
    background-image: url('../../asserts/images/Media/video_songs_icon_active.svg');
  }

  .icon-sermons {
    background-image: url('../../asserts/images/Media/sermon_icon.svg');
  }
  .icon-sermons-active{
    background-image: url('../../asserts/images/Media/sermon_icon_active.svg');
  }
.owl-prev,
.owl-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 65px;
    font-weight: 100!important;
    font-family: Roboto!important;
    color: #FFF!important;
    background-color: #4d4d4d!important;
    border-radius: 50%!important;
    padding-bottom: 10px!important;
    line-height: 0px!important;
    width: 50px;
    height: 50px;
}

.owl-prev:hover,
.owl-next:hover {
    border: none!important;
   background-color: #4d4d4d!important;
   color: #FFF!important;
    opacity: 0.9!important;
}

.owl-prev {
  left: -4.5rem;
}

.owl-next {
  right: -4.5rem;
}

.owl-carousel .owl-nav button.owl-prev, .owl-carousel .owl-nav button.owl-next, .owl-carousel button.owl-dot {
  padding-bottom: 7px !important;
  outline: none;
}
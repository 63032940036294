.media-ministry {    
    background-size: cover!important;    
    display: flex;
    justify-content: center;
    align-items: center;
    height: 369px;
    position: relative;
}

.audio-songs {
    background: url('../../../asserts/images/HomePage/Audio_Song.png');
}

.video-songs {
    background: url('../../../asserts/images/HomePage/Video_Song.png');
} 

.video-sermon {
    background: url('../../../asserts/images/HomePage/Video_Sermon.png');
} 


.media-ministry .media-header {
font-size: 60px;
font-style: normal;
font-weight: bold;
line-height: 70px;
color: #FFFFFF;
font-family: 'Roboto Mono';
}

.media-ministry .overlay-effect-1 {
    position: absolute;
    background: rgba(95, 0, 85, 0.6);
}
.media-ministry .overlay-effect-2 {
    position: absolute;
    background: rgba(63, 183, 21, 0.6);
}

.media-ministry .overlay-effect-3 {
    position: absolute;
    background: rgba(173, 53, 53, 0.6);
}
.media-contain {
    z-index: 1;
    
}

.media-contain .btn-custom {
  color: #FFFFFF;
  line-height: 23px;
  font-weight: normal;
  font-size: 20px;
  font-family: 'Roboto';
  background: rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  padding: 17px 35px;
}

.media-link, .media-link:hover {
    color: #FFFFFF;
    text-decoration: none;
}